import { i18n } from "@/plugins/i18n";
import { TableFieldType, TableHeader } from "helix-vue-components";

export const TemplateModelTableMetadata: TableHeader[] = [
  {
    label: i18n.t("templates.name").toString(),
    value: "name",
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true
  },
  {
    label: i18n.t("templates.type").toString(),
    value: "type",
    fieldComponent: TableFieldType.string,
    class: "tdt__headers__fieldLong",
    sortable: true
  },
  {
    label: i18n.t("templates.creation_date").toString(),
    value: "created_at",
    fieldComponent: TableFieldType.fnsDate,
    class: "tdt__headers__fieldLong",
    sortable: true
  },
  {
    label: i18n.t("templates.default").toString(),
    value: "default",
    fieldComponent: TableFieldType.bool,
    sortable: true
  }
];
