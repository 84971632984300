import TemplateEditor from "@/components/templateEditor/editors.declaration";
import { ResetService } from "@/decorators/resetService.decorator";
import { pusherEvents } from "@/enums/pusherEvents";
import { TemplateModelTableMetadata } from "@/metadata/templates";
import { templateActionService } from "@/services/template.action.service";
import { templatesService } from "@/services/templates.service";
import { Callback, PageNavAction } from "@/types/types";
import {
  ActionSubheader,
  TableAction,
  TableComponent,
  TableHeader,
  TablePagination
} from "helix-vue-components";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import AddTemplateComponent from "./addTemplate/AddTemplate.component";
import Template from "./TemplateManager.template.vue";

const namespace: string = "TemplateModelModule";

@Component({
  mixins: [Template],
  components: {
    TableComponent
  },
  inject: ["$changes"]
})
@ResetService(templatesService)
export default class TemplateManager extends Vue {
  @Getter("templates", { namespace })
  public templates!: TemplateEditor.TemplateModel[];
  @Getter("pagination", { namespace })
  public pagination!: TablePagination;
  @Getter("loading", { namespace })
  public loading!: boolean;
  @Action("loadTemplates", { namespace })
  public getTemplatesAction!: Callback;
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  public headers: TableHeader[] = TemplateModelTableMetadata;
  public paginate = templatesService.paginationAction();
  public rowActions: TableAction[] = templateActionService.getRowActions();
  public generalActions: ActionSubheader[] = templateActionService.getGeneralActions(
    this.openAddModal
  );

  constructor() {
    super();
    this.getTemplatesAction();
  }

  public openAddModal() {
    this.$modals.load(AddTemplateComponent, {
      size: "fit",
      positionY: "top"
    });
  }

  public changePagination(pagination: TablePagination) {
    this.paginate({
      currentPage: pagination.currentPage,
      itemsPerPage: pagination.itemsPerPage
    });
  }

  public filter(header: TableHeader) {
    templatesService.sortQuery(header);
  }

  protected mounted() {
    this.setPageNav({
      title: "templates.title",
      rightActions: {
        generalActions: () => this.generalActions,
        onSearch: templatesService.searchEvent(),
        onClose: this.onClose
      }
    });
    this.$changes.watch(pusherEvents.templateTouched, this.getTemplatesAction);
  }

  private onClose(update?: boolean) {
    if (update) {
      templatesService.searchEvent()("");
    }
  }
}
