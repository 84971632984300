import { store } from "@/internal";
import { i18n } from "@/plugins/i18n";
import {
  ActionSubheader,
  TableAction,
  TableSuccessModalResponse
} from "helix-vue-components";
class TemplateActionService {
  public getGeneralActions(openAddModal: () => void): ActionSubheader[] {
    return [
      {
        icon: "fal fa-plus",
        action: openAddModal
      },
      {
        icon: "fal fa-search",
        otherComponent: {
          name: "TableSearchComponent"
        }
      }
    ];
  }

  public getRowActions(): TableAction[] {
    return [
      {
        icon: "fal fa-pen",
        action: template => {
          store.dispatch("RouterModule/go", {
            name: "template-edit",
            params: {
              type: template.type,
              id: template.id
            }
          });
        }
      },
      {
        icon: "fal fa-trash-alt",
        modalActions: {
          modalNumber: 1,
          modalQuestion: i18n.t("template_delete_confirm").toString(),
          modalSuccessText: "yes",
          modalSuccessAction: (arg: TableSuccessModalResponse) => {
            store
              .dispatch("TemplateModelModule/deleteTemplateModel", arg.item)
              .finally(arg.unselectModal);
          },
          modalCancelText: "No"
        }
      }
    ];
  }
}

export const templateActionService = new TemplateActionService();
