import { TemplateConfig } from "@/interfaces/templateEditor";

export default TemplateEditor;

export type Type<T> = new (...args: any[]) => T;

export namespace TemplateEditor {
  export enum CONFIGURATION_FIELDS {
    BOOLEAN_BUTTON = "boolean-button",
    BOOLEAN_BUTTON_GROUP = "boolean-button-group",
    CHECKBOX = "checkbox",
    COLOR_PICKER = "color-picker",
    SELECT = "select",
    SWITCH = "switch",
    TEXT = "text",
    UPLOAD_FILE = "upload"
  }

  export enum UI_BLOCKS {
    BARCODE = "barcode-item",
    IMAGE = "image-item",
    LINE = "line-item",
    TEXT = "text-item",
    VAR = "var-item",
    LIST = "list-item"
  }

  export const UIBlocks: string[] = [];

  // INTERFACES
  export interface TemplateType {
    name: string;
    configuration: TemplateTypeConfiguration;
  }

  export interface TemplateTypeConfiguration {
    can_change_sizes?: boolean;
    can_make_default?: boolean;
    aggregableItems?: any[];
    uiBlocks?: { [key: string]: UIBlocks.CustomConfiguration };
  }
  export interface TemplateModel {
    [key: string]: any;
    _id?: string | null;
    name: string;
    type: string;
    creation_date?: string;
    default: boolean;
    size?: {
      width: number;
      height: number;
      unit: "px" | "%" | "in";
    };
    config?: TemplateEditor.TemplateModel | TemplateConfig;
    tree?: UIBlocks.LayoutItem[];
    items?: UIBlocks.BaseBlockModel[];
  }
  export namespace UIBlocks {
    export interface CustomConfiguration {
      [key: string]: any;
      fields: { [key: string]: any };
    }
    /* EXPOSED INTERFACE FOR A UIBLOCK ///*/
    export interface Block {
      configuration: BaseConfigDefinition;
      model: BaseBlockModel;
    }
    /* CONFIGURATIONS /////////////////// */
    export interface BaseConfigDefinition {
      title?: string;
      subtitle?: string;
      sections?: ConfigSection[];
    }
    export interface ConfigSection {
      title: string;
      fields: { [key: string]: ConfigField | ConfigGroup };
    }
    export interface ConfigGroup {
      type: "group";
      fields: { [key: string]: ConfigField };
    }
    export interface ConfigField {
      type: string;
      configuration?: { [key: string]: any };
    }
    /* MODEL /////////////////////////// */
    export interface LayoutItem {
      type: string;
      model: { [key: string]: any; children?: LayoutItem[] };
    }
    export interface LayoutColumn extends LayoutItem {
      type: "column-item";
      model: {
        children: LayoutItem[];
      };
    }
    export interface LayoutRow extends LayoutItem {
      type: "row-item";
      model: {
        children: LayoutItem[];
      };
    }

    export interface BaseBlockModel extends LayoutItem {
      model: { [key: string]: any };
      type: string;
      position?: {
        x: number;
        y: number;
      };
      newBlock?: boolean;
    }
  }
}

export const defaultTemplateModel: TemplateEditor.TemplateModel = {
  name: "",
  type: "",
  default: false,
  size: {
    width: 2,
    height: 2,
    unit: "in"
  },
  items: [],
  tree: []
};
