import {
  defaultTemplateModel,
  TemplateEditor
} from "@/components/templateEditor/editors.declaration";
import { templatesService } from "@/services/templates.service";
import { PageNavAction } from "@/types/types";
import { Component, Vue } from "vue-property-decorator";
import { RawLocation } from "vue-router";
import { Action } from "vuex-class";
import Template from "./AddTemplate.template.vue";

@Component({
  mixins: [Template]
})
export default class AddTemplateComponent extends Vue {
  public availableTemplateTypes: TemplateEditor.TemplateType[] = [];
  public isFormValid = false;
  public model: TemplateEditor.TemplateModel = { ...defaultTemplateModel };
  @Action("setPageNav", { namespace: "PageNavModule" })
  public setPageNav!: PageNavAction;
  public close() {
    this.$emit("reject");
  }

  public mounted() {
    this.availableTemplateTypes = templatesService.getAvailableTypes();
  }
  public create() {
    const route: RawLocation = {
      name: "template-add",
      params: {
        type: this.model.type
      }
    };

    this.setPageNav({
      title: "templates.new"
    });

    if (this.hasSizeOptions) {
      // @ts-ignore
      route.params = {
        ...route.params,
        width: String(this.model.size!.width),
        height: String(this.model.size!.height),
        unit: String(this.model.size!.unit)
      };
    }

    this.$emit("resolve");
    this.$router.push(route);
  }
  get hasSizeOptions() {
    const type = this.model.type.toLowerCase();
    return type === "inventory" || type === "customer";
  }

  public requiredRule(value: any) {
    return !!value || this.$t("required_field").toString();
  }
}
